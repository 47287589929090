.typeahead .react-select__control {
    border-radius: calc(6rem / 16);
    padding: calc(6rem / 16) calc(12rem / 16);
    font-size: calc(12rem / 16);
    color: var(--color-dark-grey);
    background: #fff;
    height: calc(44rem / 16);
    box-shadow: none;
}
.typeahead--bordered .react-select__control {
    border: calc(1rem / 16) solid var(--color-light-grey);
}
.form-control-icon + .typeahead .react-select__control {
    padding-left: calc(42rem / 16);
}
.typeahead .react-select__placeholder {
    color: var(--color-text-muted);
}
.typeahead .typeahead__submit {
    text-decoration: none;
    font-size: calc(12rem / 16);
    letter-spacing: calc(.5rem / 16);
    padding: 0 calc(6rem / 16) 0 calc(10rem / 16);
}
.react-select__indicators {
    opacity: 0;
    transition: opacity 200ms ease-in-out;
}
.has-value .react-select__indicators,
.react-select__control--menu-is-open .react-select__indicators  {
    opacity: 1;
}
.typeahead__divider {
    background: var(--color-grey-50);
    position: relative;
    width: calc(1rem / 16);
    height: calc(16rem / 16);
    opacity: .8;
}
.btn.typeahead__reset {
    color: var(--color-grey-50);
    cursor: pointer;
    padding: 0 calc(10rem / 16);
    line-height: 1;
    font-size: calc(12rem / 16);
}
.react-select__indicator-separator.react-select__indicator-separator,
.react-select__loading-indicator.react-select__loading-indicator {
    display: none;
}
.typeahead .react-select__value-container {
    padding: 0;
    width: 100%;
    flex-grow: 1;
    display: grid;
    grid-template-columns: 1fr;
}
.typeahead .react-select__input {
    width: 100%;
}
.typeahead .react-select__input > input {
     width: 90% !important;
}
.typeahead .react-select__indicator {
    padding: 0;
}
.react-select__input-container.react-select__input-container {
    margin: 0;
    //font-size: calc(16rem / 16); oder font-size 100% probieren
    font-size: calc(12rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(12rem / 16);
    }
}
.typeahead .react-select__menu {
    margin: calc(1rem / 16) 0 0 0;
    border-radius: calc(6rem / 16);
    padding: calc(6rem / 16) calc(7rem / 16);
    box-shadow: 0 calc(10rem / 16) calc(20rem / 16) 0 rgba(62,61,64,0.1);
    z-index: 13;

    @media screen and (min-width: 768px) {
        padding: calc(6rem / 16) calc(7rem / 16) calc(20rem / 16);
    }
}
.typeahead .react-select__menu:after {
    content: '';
    position: absolute;
    bottom: calc(6rem / 16);
    left: 0;
    right: 0;
    height: calc(36rem / 16);
    background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
    z-index: 5;
    pointer-events: none;
}
.typeahead .react-select__menu-list {
    padding: 0 calc(8rem / 16) 0 calc(10rem / 16);
    max-height: 32vh;

    @media screen and (min-width: 768px) {
        max-height: 64vh;
        padding: 0 calc(12rem / 16) 0 calc(15rem / 16);
    }
}
.typeahead .react-select__option {
    color: var(--color-text-default);
    font-size: calc(12rem / 16);
    letter-spacing: calc(.5rem / 16);
    padding: 0 calc(8rem / 16);
    cursor: pointer;

    @media screen and (min-width: 768px) {
        font-size: calc(14rem / 16);
        padding: 0 calc(12rem / 16);
    }
}
.typeahead .react-select__option--is-focused,
.typeahead .react-select__option:hover {
    color: var(--color-primary);
    text-decoration: underline;
}
.typeahead .react-select__option:active {
    background: none;
}
.typeahead .react-select__option:empty {
    display: none;
}
.typeahead .react-select__group + .react-select__group {
    margin-top: 4px;
}
.typeahead .react-select__group-heading  {
    font-family: var(--font-default-bold);
    font-weight: var(--font-default-bold-weight);
    text-transform: uppercase;
    color: var(--color-primary);
    font-size: calc(13rem / 16);
    border-bottom: calc(1rem / 16) solid var(--color-grey-50);
    letter-spacing: calc(1rem / 16);
    padding: calc(5rem / 16) 0;
    margin-bottom: calc(8rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(15rem / 16);
        padding: calc(8rem / 16) 0;
    }
}
.typeahead__group-icon {
    vertical-align: -.1em;
    margin-right: calc(5rem / 16);
    color: var(--color-default);
}

.typeahead__more {
    color: var(--color-default);
    text-transform: none;
    font-size: calc(10rem / 16);
    letter-spacing: calc(.5rem / 16);
    line-height: calc(18/14);
    font-family: var(--font-default);
    font-weight: var(--font-default-weight);

    @media screen and (min-width: 768px) {
        font-size: calc(14rem / 16);
    }
}
.typeahead__more:hover {
    text-decoration: underline;
}
.typeahead__more__icon {
    font-size: calc(7rem / 16);
    margin-right: calc(4rem / 16);
    position: relative;

    @media screen and (min-width: 768px) {
        top: calc(-1rem/16);
        font-size: .5rem;
        margin-right: .4375rem;
    }
}

.react-select__menu-list::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 calc(8rem / 16) rgba(0, 0, 0, 0.2);
    background-color: transparent;
    transition: all 120ms ease;
}

.react-select__menu-list::-webkit-scrollbar {
    width: calc(2rem / 16);
    height: calc(2rem / 16);
    background: var(--color-sand-20);
    transition: all 120ms ease;
}

.react-select__menu-list::-webkit-scrollbar-thumb {
    background-color: var(--color-primary);
    transition: all 120ms ease;
}

/* proxymode search */
.typeahead--proxymode .react-select__option:not(:last-child) {
    margin-bottom: calc(5rem / 16);
    padding-bottom: calc(5rem / 16);
    border-bottom: calc(1rem / 16) solid var(--color-light-grey);
}
.overlay.is-open {
    transform: translateX(0);
}
.overlay__head {
    padding: calc(22rem / 16);
    border-bottom: calc(1rem / 16) solid var(--color-light);
}
.overlay__head-title {
    font-size: calc(20rem / 16);
    line-height: calc(22/20);
}
.overlay__head-icon {
    font-size: calc(10rem / 16);
    margin-right: calc(10rem / 16);
}
.overlay__head-close {
    color: var(--color-grey-50);
    font-size: calc(13rem / 16);
    letter-spacing: calc(.5rem / 16);
    line-height: 1;
}
.overlay__submit {
    width: 100%;
    padding: calc(10rem / 16);
    height: calc(66rem / 16);
    background: var(--color-light);
    color: var(--color-primary);
    border: 0;
    flex-shrink: 0;
    margin-top: auto;
}
@media screen and (max-width: 767px) {
    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        height: 100vh;
        height: -webkit-fill-available;
        background: #fff;
        color: var(--color-default);
        z-index: 12;
        transform: translateX(100%);
        transition: transform 200ms ease-in-out;
        display: flex;
        flex-direction: column;
    }
    .filter-box.overlay {
        padding: 0;
    }

    .overlay__content {
        padding: calc(22rem / 16);
        flex-grow: 1;
    }
}

@media screen and (min-width: 768px) and (max-width: 1199px) {
    .overlay--visible-lg {
        position: fixed;
        top: 0;
        left: 0;
        width: calc(400rem / 16);
        height: 100vh;
        background: #fff;
        color: var(--color-default);
        z-index: 12;
        transform: translateX(-100%);
        transition: transform 200ms ease-in-out;
        display: flex;
        flex-direction: column;
    }

    .overlay--visible-lg .overlay__content {
        padding: calc(22rem / 16);
    }
}

@media screen and (min-width: 768px) {
    .overlay--show-md{
        position: absolute;
        left: 0;
        top: 0;
        width: calc(842rem / 16);
        background: #fff;
        border-radius: calc(6rem / 16);
        box-shadow: 0 calc(10rem / 16) calc(20rem / 16) 0 rgba(0,0,0,0.1);
        z-index: 10;
        visibility: hidden;
        opacity: 0;
        transition: opacity 300ms ease-in-out,visibility 300ms ease-in-out;
    }
    .is-open.overlay--show-md {
        visibility: visible;
        opacity: 1;
    }
    .overlay--show-md .overlay__content {
        padding: calc(34rem / 16);
    }
}
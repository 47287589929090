.filter-box {
    box-shadow: 0 0 calc(20rem / 16) 0 rgba(62,61,64,0.1);
    padding: calc(22rem / 16);
    background: #fff;

    @media screen and (min-width: 1400px) {
        padding: calc(44rem / 16);
    }
}

.filter-box--grey {
    box-shadow: none;
    background: var(--color-light);
}

.filter-box-md {
    @media screen and (min-width: 1200px) {
        box-shadow: 0 0 calc(20rem / 16) 0 rgba(62,61,64,0.1);
        padding: calc(22rem / 16);
        background: #fff;
    }
    @media screen and (min-width: 1400px) {
        padding: calc(44rem / 16);
    }

    @media screen and (max-width: 767px) {
        padding: 0;
    }
}

.filter-box__title {
    font-size: calc(20rem / 16);
    line-height: calc(22/20);
}
.filter-box__title-icon {
    font-size: calc(10rem / 16);
    margin-right: calc(10rem / 16);
}
.filter-box__title-icon--lg {
    font-size: calc(16rem / 16);
}
.filter-box__group {
    padding: 0 calc(22rem / 16);
    position: relative;
}
.filter-box__group--sm {
    padding: 0;
}
.filter-box__group + .filter-box__group {
    padding-top: calc(22rem / 16);
    margin-top: calc(22rem / 16);
}
.filter-box__group:not(.filter-box__group--sm) + .filter-box__group:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: calc(1rem / 16);
    background: #E0E0E0;
}
.filter-box__group-title {
    margin: 0 calc(-22rem / 16) calc(16rem / 16);
    font-size: calc(15rem / 16);
}
.filter-box__group-btn {
    background: none;
    border: 0;
    color: inherit;
    box-shadow: none;
    display: flex;
    align-items: center;
    grid-gap: calc(6rem / 16);
}
.filter-box__group-btn-icon {
    transform: scaleY(-1) translateY(calc(2rem / 16));
    font-size: calc(6rem / 16);
    transition: 200ms ease-in-out;
}
.collapsed > .filter-box__group-btn-icon {
    transform: scaleY(1);
}
.filter-box__group-collapse:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: calc(50rem / 16);
    background: linear-gradient(180deg, rgba(255,255,255,0) 0%, #FFFFFF 100%);
    pointer-events: none;
    opacity: 0;
    transition: opacity 150ms ease-in-out;
}
.is-collapsed .filter-box__group-collapse:before {opacity: 1;}

.filter-box__group-collapse-hint {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    border: none;
    z-index: 1;
    opacity: 0;
    transition: opacity 200ms ease-in-out;
    background: none;
    padding: calc(15rem / 16) calc(10rem / 16) calc(5rem / 16);
    pointer-events: none;
}
.filter-box__group-collapse-icon {
    font-size: calc(8rem / 16);
}
.is-collapsed .filter-box__group-collapse-hint {
    pointer-events: auto;
}
.is-collapsed .filter-box__group-collapse-hint:hover {
    opacity: 1;
}
@media (hover: none) {
    .filter-box__group-collapse-hint {
        opacity: 0;
    }
}

/* sizes */
.filter-box--xs {
    padding: calc(11rem / 16);

    @media screen and (min-width: 1400px) {
        padding: calc(11rem / 16);
    }
}

.filter-box.filter-box--sm {
    padding: calc(10rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(22rem / 16);
    }
}

.filter-box--in-modal {
    width: calc(366rem / 16);
    max-width: 100%;

    @media screen and (min-width: 768px) {
        box-shadow: 0 0 calc(20rem / 16) 0 rgba(62,61,64,0.1);
        padding: calc(22rem / 16);
        background: #fff;
    }

    @media screen and (min-width: 1400px) {
        padding: calc(44rem / 16);
    }
}

@media screen and (max-width: 767px) {
    .filter-box--in-modal .overlay {
        opacity: 0;
        transition: transform 200ms ease-in-out, opacity 200ms ease-in-out;
    }
    .filter-box--in-modal .overlay.is-open {
        opacity: 1;
    }
}

/* category list */
.filter-box__category + .filter-box__category {
    margin-top: calc(8rem / 16);
}
.filter-box__category-optional-btn {
    padding: 0;
    background: none;
    border: 0;
    color: currentColor;
    font-family: var(--font-default-bold);
    font-weight: var(--font-weight-bold);
    font-size: calc(12rem/16);
    letter-spacing: calc(.2rem / 16);
}
.filter-box__category-title {
    padding: 0;
    background: none;
    border: 0;
    color: currentColor;
    font-family: var(--font-default-bold);
    font-weight: var(--font-weight-bold);
    font-size: calc(15rem/16);
    line-height: calc(18/15);
    letter-spacing: calc(.2rem / 16);
    padding-right: calc(18rem / 16);
    position: relative;
}
.filter-box__category-icon {
    font-size: calc(5rem / 16);
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%) scaleY(-1);
    transition: transform 200ms ease-in-out;
}
.collapsed .filter-box__category-icon {
    transform: translateY(-50%) scaleY(1);
}
.filter-box__category-list {
    padding-left: calc(14rem / 16);
}
.filter-box__category-link-icon {
    font-size: calc(8rem / 16);
    margin-left: calc(5rem / 16);
}
.filter-box__category-link {
    color: var(--color-grey-50);
    font-size: calc(14rem / 16);
    letter-spacing: calc(.2rem / 16);
    transition: color 200ms ease-in-out;
}
.filter-box__category-link.is-active,
.filter-box__category-link:hover {
    color: var(--color-primary);
}